import { AssetType } from "@iventis/domain-model/model/assetType";
import { publicCdnAssetUrl } from "../constants/assets";

export const getModelAssetUrl = (assetId: string) => `${publicCdnAssetUrl}/${AssetType.Model}/${assetId}`;

export const getIconAssetUrl = (assetId: string) => `${publicCdnAssetUrl}/${AssetType.MapIcon}/${assetId}`;

export const getBackgroundJson = (assetId: string) => `${publicCdnAssetUrl}/${AssetType.MapBackground}/${assetId}`;

export const getModelConfigJson = (id: string) => `${publicCdnAssetUrl}/ModelConfig/${id}`;

export const getMapJson = (id: string) => `${publicCdnAssetUrl}/${AssetType.MapJsonExport}/${id}`;

export const getImageUrl = (assetId: string) => `${publicCdnAssetUrl}/${AssetType.Image}/${assetId}`;
